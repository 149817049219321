
var winW;
var headerH;
var adjustH = 40;
var _headerH = 56;//子病院のヘッダー

// --vhというカスタムプロパティを作成
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
// window resize
var $winWidth = $(window).width();
$(window).on('resize', function(){
  var $winWidthResize = $(window).width();
  if($winWidth !== $winWidthResize) {
    window.addEventListener('resize', () => {
			vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		});
  }
});


//----- header addust
$(window).on('load resize', function() {
	winW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	headerH = $('.l_header').outerHeight();
	adjustH = headerH + 0;
	// if(winW > 1270) {
	if(winW > 1024) {

		if ($('.is-clinic').hasClass('is-navOpen')) {
			//各病院
			if ($('.l_header-clinic').hasClass('is-navOpen')) {
				$('.l_header-clinic').removeClass('is-navOpen');
				$('.l_main').removeClass('is-navOpen');
				$('body').removeClass('js-fixed').css({
					'top': 0
				});
				//window.scrollTo(0, scrollpos);
				$('.l_header-clinic-nav-wrap').css("display","flex");
			}else{
				$('.l_header-clinic-nav-wrap').css("display","flex");
			}
		} else {
			//総合TOP
			if ($('.l_header').hasClass('is-navOpen')) {
				$('.l_header').removeClass('is-navOpen');
				$('.l_main').removeClass('is-navOpen');
				$('body').removeClass('js-fixed').css({
					'top': 0
				});
				//window.scrollTo(0, scrollpos);
				$('.l_header-nav-wrap').css("display","flex");
			}else{
				$('.l_header-nav-wrap').css("display","flex");
				$('.l_header-clinic-nav-wrap').css("display","flex");
			}
		}

	}else {
		$('.l_header-nav-wrap').css("display","none");
		if ($('.l_header').hasClass('is-navOpen')) {
			$('.l_header').removeClass('is-navOpen');
			$('.l_main').removeClass('is-navOpen');
			$('body').removeClass('js-fixed').css({
				'top': 0
			});
		}

		$('.l_header-clinic-nav-wrap').css("display","none");
		$('#l_header_clinic_nav').removeClass('is-bgColor');
		if ($('.l_header-clinic').hasClass('is-navOpen')) {
			$('.l_header-clinic').removeClass('is-navOpen');
			$('.l_main').removeClass('is-navOpen');
			$('body').removeClass('js-fixed').css({
				'top': 0
			});
		}
	}

	/* ヘッダーナビアクティブ判定*/
	var nav_header = $('.l_header-nav__itemParent');
	nav_active(nav_header);

	
	



	

	var num = $('.uq_operation--surgery--list ul').children('li').length;
	if (num <11) {
		if(winW < 750) {
			$('#surgeryBtn').hide();
		}
	};

});

$(function() {
	var w = $(window).width();
	winW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    var w_resize;
    var timer = false;
 
    Responsive();
 
    $(window).on("orientationchange resize",function() {
        if (timer !== false) {
            clearTimeout(timer);
        }
        timer = setTimeout(function() {
            w_resize = $(window).width();
            if (w != w_resize){
                Responsive();
                w = $(window).width();
            }
        }, 200);
    });
 
    function Responsive () {
			var facilit_hideList = '.uq_operation__facility li:nth-of-type(n+4)';
			/* 手術の一例のもっと見る*/
			var hideList = '.uq_operation--surgery--list ul li:nth-of-type(n+4)';
			if(winW < 750) {
				$(facilit_hideList).css("display","initial");
				$(facilit_hideList).hide();
				$('#facilityBtn').text('さらに表示する');
				$('#facilityBtn').removeClass('is-accoOpen');
				$(hideList).hide();
				$('#surgeryBtn').text('さらに表示する');
				$('#surgeryBtn').removeClass('is-accoOpen');
			}else{
				$(hideList).css("display","block");
				$(facilit_hideList).css("display","flex");
			}
    }
	$('#facilityBtn').click(function() {
		if(winW < 750) {
			var facilit_hideList = '.uq_operation__facility li:nth-of-type(n+4)';
			$(facilit_hideList).toggle();

			if ($('#facilityBtn').hasClass('is-accoOpen') ) {
				$('#facilityBtn').text('さらに表示する');
				$('#facilityBtn').removeClass('is-accoOpen');
			}else {
				$('#facilityBtn').addClass('is-accoOpen');
				$('#facilityBtn').text('閉じる');
			}
			
			return false;
		}
	});

	$('#surgeryBtn').click(function() {
		if(winW < 750) {
			var hideList = '.uq_operation--surgery--list ul li:nth-of-type(n+4)';
			$(hideList).toggle();

			if ($('#surgeryBtn').hasClass('is-accoOpen') ) {
				$('#surgeryBtn').text('さらに表示する');
				$('#surgeryBtn').removeClass('is-accoOpen');
			}else {
				$('#surgeryBtn').addClass('is-accoOpen');
				$('#surgeryBtn').text('閉じる');
			}
			
			return false;
		}
	});
});


/* ヘッダーナビアクティブ判定
---------------------------------------------------------- */
function nav_active($target) {
	$target.each(function() {
			$href = $(this).attr('href');
			if ($(this).parent().attr('class').indexOf('recuit') > -1){
					//アクティブにしない。
			}else{
					if ($href=='/useful/') {
							var $dir = location.href.split("/");
							var $dir2 = "/"+$dir[3]+"/";
							if( $dir2.match($href) ) {
									$(this).addClass('is-active');
							}
					}else {
							var $dir = location.href.split("/");
							var $dir2 = "/"+$dir[$dir.length -2]+"/";
							var $dir_href = $href.split("/");
							var $dir_href2 = "/"+$dir_href[$dir_href.length -2];
							if( $dir2.match($dir_href2 + "/") ) {
									$(this).addClass('is-active');
							}
					}
			}
	});
}

//----- header scroll
var winH = $(window).innerHeight()
var $header = $('.l_header');
var $headerClinic = $('.l_header-clinic');
var $header_clinic_nav = $('#l_header_clinic_nav');
var $body = $('body');
var $trackingbar = $('#trackingbar');
var $headerCliniceight = $headerClinic.innerHeight();
$(window).on('scroll', function() {
	if ($(this).scrollTop() > winH/3) {
		$header.addClass('is-showAll is-bgColor');
	}
	else {
		$header.removeClass('is-bgColor');
	}
	//病院TOPのヘッダー
	winW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	if(winW > 1270) {
		if ($(this).scrollTop() > 98) {
			$header_clinic_nav.addClass('is-showAll is-bgColor');
			$body.addClass('is-bgColor');
		}else {
			$header_clinic_nav.removeClass('is-bgColor');
			$body.removeClass('is-bgColor');
		}
	}

	var now = $(this).scrollTop();

	//病院TOPの右下の追尾バー
//console.log(mvHeight);
	var $mvHeight = $('#mv').innerHeight();
	var $footerHeight = $('#footer').innerHeight();
	if (($mvHeight - $headerCliniceight) < now) {
		$trackingbar.addClass('is-fadeIn');
	} else {
		$trackingbar.removeClass('is-fadeIn');
	}

	/*
	if ($trackingbar.hasClass('is-fadeIn')) {
		if (($footerHeight - $headerCliniceight) < now) {
			$trackingbar.removeClass('is-fadeIn');
		} else {
			$trackingbar.addClass('is-fadeIn');
		}
	}*/
	
	
	if($('.js-leadPos').length){
		$('.js-leadPos').addClass('is-stop');
	}
});


$(function() {
	var $outer = $('html, body');
	var $header = $('.l_header');
	var $navWrap = $('.l_header-nav-wrap');
	var $headerClinic = $('.l_header-clinic');
	var $navWrapClinic = $('.l_header-clinic-nav-wrap');
	var $navBtn = $('.js-spNavBtn');
	var $navClinicBtn = $('.js-spNavClinicBtn');
	
	var scrollpos;

//----- header nav PC onmouse
	$('.l_header-nav__item.is-hasChild').hover(
		function() {
			if(winW > 1024) {
				$('.l_wrap').stop(true).addClass('is-navOpen');
			}
		},
		function () {
			if(winW > 1024) {
				$('.l_wrap').removeClass('is-navOpen');
			}
		}
	);
//----- メニューをクリックしたらナビを消す
	//$('.l_header-nav__item a','.l_header-clinic-nav__item a' ).on('click', function() {
	$('.l_header-clinic-nav__item a').on('click', function() {
		if(winW > 750) {
			//$navWrap.fadeToggle(300);
		} else {
			$navWrap.slideUp(300);
			$navWrapClinic.slideUp(300);
		}
		$header.removeClass('is-navOpen');
		$headerClinic.removeClass('is-navOpen');
		$('.l_wrap').removeClass('is-navOpen');
		$('.l_main').removeClass('is-navOpen');
	});
	// ナビの最下部のメニュー
	$('.l_header-clinic__bottom-nav a').on('click', function() {
		if(winW > 750) {
			//$navWrap.fadeToggle(300);
		} else {
			$navWrap.slideUp(300);
			$navWrapClinic.slideUp(300);
		}
		$header.removeClass('is-navOpen');
		$headerClinic.removeClass('is-navOpen');
		$('.l_wrap').removeClass('is-navOpen');
		$('.l_main').removeClass('is-navOpen');
	});

//----- header nav accordion
	$navBtn.on('click', function(e) {
		_spNav($(this));
		$navWrap.slideToggle(300);
	});
	$navClinicBtn.on('click', function(e) {
		_spClinicNav($(this));
		$navWrapClinic.slideToggle(300);
	});
	$('.js-spNavClose').on('click', function() {
		$navWrap.slideUp(300);
		$header.removeClass('is-navOpen');
		$navWrapClinic.slideUp(300);
		$headerClinic.removeClass('is-navOpen');
	});
	$('.js-accoBtnSP').on('click', function() {
		if(winW <= 1024) {
			_accoSP($(this));
		}
	});
	$('.js-accoBtn').on('click', function() {
			_accoSP($(this));
	});


	//----- お役立ち情報　tab
	if($('.uq_useful-disease-tab').length){
		var tabNum = $('.uq_useful-disease-tab').length;
		$('.uq_useful-disease-tab-wrap').addClass('is-count0'+ tabNum );

		//----- tab
		var hash = location.hash;
		var href = location.href;
		var $tab = $('.uq_useful-disease-tab');
		var $tabContent = $('.uq_useful-disease-tab-content');

		if(hash.length){
			if(hash.match(/#cat/)){
				$tabContent.hide();
				$tab.removeClass('is-act');
				var tabName = hash.slice(4.1);
				tabName = tabName - 1;
				$tabContent.eq(tabName).fadeIn();
				$tab.eq(tabName).addClass('is-act');
			} else {
				$tab.eq(0).addClass('is-act');
				$tabContent.hide();
				$tabContent.eq(0).fadeIn();
			}
		}else if(href.length) {
			if(href.match(/cat/)) {
				$tabContent.hide();
				$tab.removeClass('is-act');
				var tabName = hash.slice(4.1);
				tabName = tabName - 1;
				$tabContent.eq(tabName).fadeIn();
				$tab.eq(tabName).addClass('is-act');
			}
		}
		$tab.on('click', function() {
			var index = $tab.index(this);

			$tabContent.hide();
			$tabContent.eq(index).fadeIn();
			$tab.removeClass('is-act');
			$(this).addClass('is-act');

		});
	}



//----- scroll
	var hasHash = location.hash;
	if(hasHash) {
		$('body, html').stop().scrollTop(0);
		setTimeout(function(){
			var target = $(hasHash);
			var position = target.offset().top - adjustH;
			$('body,html').stop().animate({scrollTop: position}, 500, 'swing');
		}, 200);
	}
	$('.js-scroll a[href^="#"], a.js-scroll[href^="#"]').on('click', function() {
		$('html, body').animate({
			scrollTop: $($(this).attr('href')).offset().top - adjustH
		}, 500, 'swing');
		return false;
	});

//----- slide In
$('.js-slideIn').on('inview', function() {
	var $this = $(this);
	$this.delay(300).queue(function(){
		$this.addClass('is-show');

		$this.find('.js-slideIn__item').each(function(idx) {
			var $child = $(this);
			$child.delay(300 * (idx + 1)).queue(function() {
				$child.addClass('is-show');
			});
		});
	});
});
$('.js-orderIn').on('inview', function() {
	var item = $(this).children();
	var delay = 600;
	item.each(function(i){
		var $this = $(this);
		$this.delay(i *delay).queue(function(next) {
			$this.addClass('is-show');
			next();
		});
	});
});



});

function _spNav() {
	if ($('.l_header').hasClass('is-navOpen')) {
		$('.l_header').removeClass('is-navOpen');
		$('.l_main').removeClass('is-navOpen');
		//window.scrollTo(0, scrollpos);
	} else {
		$('.l_header').addClass('is-navOpen');
		$('.l_main').addClass('is-navOpen');
		//scrollpos = $(window).scrollTop();
	}
	
}
function _spClinicNav() {
	if ($('.l_header-clinic').hasClass('is-navOpen')) {
		$('.l_header-clinic').removeClass('is-navOpen');
		$('.l_main').removeClass('is-navOpen');
		$('.m_pagetop').slideToggle(0);
		//window.scrollTo(0, scrollpos);
	} else {
		$('.l_header-clinic').addClass('is-navOpen');
		$('.l_main').addClass('is-navOpen');
		$('.m_pagetop').slideToggle(0);
		
		//scrollpos = $(window).scrollTop();
	}
}
function _accoSP($this) {
	if ($this.hasClass('is-accoOpen')) {
		$this.removeClass('is-accoOpen');
	} else {
		$this.addClass('is-accoOpen');
	}
	$this.next().slideToggle(300);
}

$('.js_more__btn').click(function (e) {
	e.preventDefault();
// console.log('click more');
	var $this = $(this);
	var target_id = $this.data('target-id');
	var $target = $('.js-more--' + target_id);

// console.log('more target [' + $target.length + ']');
	if ($target.length > 0) {
		$target.slideDown(100);
		$this.hide();
	}
});





var ENGINE = ENGINE || {};
ENGINE.common = {
	init: function() {
		// 高さ揃え
		$('.js-matchHeight').matchHeight();
	}
};

/////////////////////////////////////////////////////////////
/**
 * お知らせ情報の表示
 *
 */
ENGINE.news = {
	init: function() {
		var $importantlist = $('.js-topImportantNews');
		var $newslist = $('.js-topNews');

		if ($newslist.length) {
			$newslist.empty();
			$importantlist.empty();
			if ($newslist.length > 0) {
				$.ajax({
					'url'      : '/news/api/',
					'type'     : 'get',
					'dataType' : 'json',
					'error'    : function() {
					},
					'success'  : function(json) {
						if (json) {
							// 重要なお知らせ
							var importantlist = json['importantlist'];
							if (importantlist) {
								for(var i=0,max=importantlist.length; i<max; i++) {
									var code = ENGINE.news.getCode4important(importantlist[i]);
									var $item = $(code);
									$importantlist.append($item);
								}
							}

							// 最新情報
							var newslist = json['newslist'];
							if (newslist) {
								for(var i=0,max=newslist.length; i<max; i++) {
									var code = ENGINE.news.getCode(newslist[i]);
									var $item = $(code);
									$newslist.append($item);
								}
							}
						}
					}
				});
			}
		}
	},
	/**
	 * 重要なお知らせ
	 *
	 */
	getCode4important: function(item) {
		var code = '';
// console.log(item);

		var link = '';
		var cls_link = '';

		// URLリンク
		if (item['url'] && (item['url'] != '')) {
			link = item['url'];
			cls_link = 'is-icon-blank';

		// PDFリンク
		} else if (item['pdf'] && (item['pdf'] != '')) {
			link = item['pdf'];
			cls_link = 'is-icon-pdf';

		}

		code += '<div class="uq_top-news-box--lead"><p>';
		if (link) {
			code += '<a href="' + link + '" target="_blank" class="uq_top-societyNews__inner is-link ' + cls_link + '">' + item['title'] + '</a>';
		} else {
			code += item['title'];
		}
		if (item['lead']) {
			code += '<br>' + item['lead'];
		}
		code += '</p></div>';

		return code;
	},
	/**
	 * 最新情報
	 *
	 */
	getCode: function(item) {
		var code = '';
// console.log(item);

		var link = '';
		var cls_link = '';

		// URLリンク
		if (item['url'] && (item['url'] != '')) {
			link = item['url'];
			cls_link = 'is-icon-blank';

		// PDFリンク
		} else if (item['pdf'] && (item['pdf'] != '')) {
			link = item['pdf'];
			cls_link = 'is-icon-pdf';
		}

		code += '<li>';
		if (link) {
			code += '<a href="' + link + '" target="_blank" class="uq_top-societyNews__inner is-link ' + cls_link + '">';
		} else {
			code += '<div class="uq_top-societyNews__inner ' + cls_link + '">';
		}
		code += '<div class="uq_top-societyNews__item">';
		code += '<div class="uq_top-societyNews__date"><p>' + item['date'] + '</p></div>';
		code += '<div class="uq_top-societyNews__txt">';
		code += '<p class="uq_top-societyNews__txt-ttl">' + item['title'] + '</p>';
		if (item['lead']) {
			code += '<p class="uq_top-societyNews__txt-lead">' + item['lead'] + '</p>';
		}
		code += '</div>';
		code += '</div>';
		if (link) {
			code += '</a>';
		} else {
			code += '</div>';
		}
		code += '</li>';

		return code;
	}
}

/////////////////////////////////////////////////////////////
/**
 * 病院検索機能
 *
 */
ENGINE.searchConditionBox = {
	init: function() {
// console.log('ENGINE.searchConditionBox.init() - start');
		var $target = $('.js-searchConditionBox');

// console.log($target.length);
		if ($target.length) {
			$target.empty();
			if ($target.length > 0) {
				$.ajax({
					'url'      : '/search/condition',
					'type'     : 'get',
					'dataType' : 'json',
					'error'    : function() {
					},
					'success'  : function(json) {
// console.log(json);
						if (json) {
							$target.html(json.code);
						}
					}
				});
			}
		}
	}
};

/////////////////////////////////////////////////////////////
/**
 * ルート検索機能（病院一覧／個病院）
 *
 */
ENGINE.RouteMap = {
	vars: {
		is_call_setup: false,
		is_dom_ready: false,
		is_geolation: false,
		lat: false,
		lng: false
	},

	/**
	 * 初期処理
	 *
	 */
	init: function() {
		// 位置情報の取得用関数が使用できるブラウザかどうかの判断
		// 使用できる場合
		// if ($('.js_map_route').length && navigator.geolocation) {
			// 取得できた場合、setPosition関数をcall、取得できなかった場合noPosition関数をcall
			// navigator.geolocation.getCurrentPosition(ENGINE.RouteMap.setPosition, ENGINE.RouteMap.noPostion);

		// 使用できない場合
		// } else {
			ENGINE.RouteMap.noPosition();
		// }

		$(function() {
			ENGINE.RouteMap.vars.is_dom_ready = true;
			ENGINE.RouteMap.setup();
		});
	},
	/**
	 * 初期処理
	 *
	 */
	setup: function() {
		if (ENGINE.RouteMap.vars.is_dom_ready && ENGINE.RouteMap.vars.is_call_setup) {
			$('.js_map_route').each(function() {
				var $this = $(this);
				if (!$this.hasClass('is_map_route__ready')) {
					var url = $this.attr('href');
					if(ENGINE.RouteMap.vars.is_geolocation) {
						url += '&saddr=' + ENGINE.RouteMap.vars.lat + ',' + ENGINE.RouteMap.vars.lng;
						$this.attr('href', url).addClass('is_map_route__ready');
					}
				}
			});
		}
	},
	/**
	 * 座標取得不可の場合のCallback
	 *
	 */
	setPosition: function(pos) {
		ENGINE.RouteMap.vars.is_geolocation = true;
		ENGINE.RouteMap.vars.lat = pos.coords.latitude;
		ENGINE.RouteMap.vars.lng = pos.coords.longitude;

		ENGINE.RouteMap.vars.is_call_setup = true;
		ENGINE.RouteMap.setup();
	},
	/**
	 * 座標取得不可の場合のCallback
	 *
	 */
	noPosition: function() {
		ENGINE.RouteMap.vars.is_geolocation = false;
		ENGINE.RouteMap.vars.lat = false;
		ENGINE.RouteMap.vars.lng = false;
	},
};


/////////////////////////////////////////////////////////////
/**
 * アンカーリンク
 *
 */
ENGINE.AnchorLink = {
	init: function() {
		$('a[href^="#"]').click(function() {
			var winW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
			var speed = 1000;
			var href= $(this).attr("href");
			var target = $(href == "#" || href == "" ? 'html' : href);
			var position = parseInt(target.offset().top);
			
			var clinic_headerHsp = $('.l_header-clinic').outerHeight() - 3;
			if(winW < 750) {
				_headerH = clinic_headerHsp
			}else {
				_headerH = 56;
			}
			var fixPosition = parseInt(position - _headerH);

			$('body,html').animate({scrollTop:fixPosition}, speed, 'easeOutQuart');
			return false;
		});

		/* ページトップ */
		$('.js_pageTop,.PageTop').click(function() {
			$('body,html').animate({scrollTop:0}, 800, 'easeOutQuart');
			return false;
		});
	},
};

/////////////////////////////////////////////////////////////
/**
 * イベントトラッキング
 *
 */
ENGINE.Conversion = {
	init : function() {
		$('.js_GA_conversion').each(function(idx) {
			var $button = $(this);

// console.log('Conversion init : ' + idx);
			$button.on('click', function() {
				// 即時にイベントトラッキングを送信する場合のみ処理
				// ※ 送信ボタンの場合、送信完了のタイミングでイベントトラッキングを送信する
				if (!$button.hasClass('js_GA_conversion_async')) {
					ENGINE.Conversion.Func.send($button);
				}
			});
		});
	}
	,Func : {
		send: function($target) {
			var gaAction   = $target.data('ga-action');
			var gaLabel    = $target.data('ga-label');
			var gaCategory = $target.data('ga-category');

			// GTM (dataLayer) の場合
			if (dataLayer && dataLayer.push && (typeof(dataLayer.push) == 'function')) {
// console.log('Conversion B');
				var gaEvent = $target.data('ga-event')?$target.data('ga-event'):'TrackEvent';
				dataLayer.push({
					event: gaEvent,
					eventCategory: gaCategory,
					eventAction: gaAction,
					eventLabel: gaLabel,
				});
			// 旧GAの場合
			} else if (ga && (typeof(ga) == 'function') ) {
// console.log('Conversion A');
				// console.log('send : ', gaCategory, gaAction, gaLabel);
				ga('send', 'event', {
					eventCategory: gaCategory,
					eventAction: gaAction,
					eventLabel: gaLabel,
					transport: 'beacon'
				});
// 			} else {
// console.log('Conversion None');
			}

		}
	}
};




/////////////////////////////////////////////////////////////
// 各種処理実行（初期処理呼び出し）
window.addEventListener('DOMContentLoaded', function() {
	// 高さ揃えなど
	ENGINE.RouteMap.init();

	// カレンダー
	ENGINE.calendar.init();

	// 高さ揃えなど
	ENGINE.common.init();

	// お知らせ崇徳
	ENGINE.news.init();

	// 病院検索
	ENGINE.searchConditionBox.init();



	// イベントトラッキング
	ENGINE.Conversion.init();


});